import {Text} from "@chakra-ui/react"
import {FC} from "react"

export const Highlight: FC = ({children}) => {
  return (
    <Text as="mark" bg="#FFF5A4" borderRadius="md">
      {children}
    </Text>
  )
}
