import {
  Button,
  HStack,
  Input,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react"
import Router from "next/router"
import {FC, FormEvent, useState} from "react"

const isBrowser = typeof window != "undefined"

export const WaitlistForm: FC<{}> = (props) => {
  const inputHeight = useBreakpointValue([12, "55px"]) ?? "55px"
  const fontSize = useBreakpointValue(["lg", "xl"])

  const [email, setEmail] = useState("")
  const [status, setStatus] = useState<"idle" | "loading" | "success">("idle")

  const submit = async (ev: FormEvent) => {
    ev.preventDefault()
    setStatus("loading")

    if (!email) return

    Router.push(`/signup?email=${email}`)

    setStatus("success")
  }

  return (
    <form onSubmit={submit}>
      <HStack
        bg="white"
        width={["100%", "550px"]}
        borderRadius="10px"
        p="2px"
        borderWidth={2}
        borderColor="black"
      >
        <Input
          placeholder="alice@example.com"
          _placeholder={{color: "stone.200"}}
          _focus={{border: "none"}}
          height={inputHeight}
          fontSize={fontSize}
          bg="transparent"
          border="none"
          type="email"
          value={email}
          onChange={(ev) => {
            setStatus("idle")
            setEmail(ev.target.value)
          }}
        />

        <Button
          type="submit"
          height={inputHeight}
          bg="landingFg.500"
          borderRadius="8px"
          _hover={{bg: "landingFg.500"}}
          // _active={{bg: "landingFg.600"}}
          fontSize={fontSize}
          fontWeight={600}
          px={[6, 8]}
          flexShrink={0}
          disabled={status === "loading"}
          pointerEvents={
            status === "loading" || status === "success" ? "none" : "auto"
          }
          color="white"
        >
          {status === "loading" ? <Spinner size="sm" mr={2} /> : null}

          {status === "loading"
            ? "Saving..."
            : status === "success"
            ? "Talk soon!"
            : "Sign up"}
        </Button>
      </HStack>
    </form>
  )
}
