import {
  Box,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react"
import localforage from "localforage"
import Image from "next/image"
import Link from "next/link"
import Router from "next/router"
import {FC, useEffect} from "react"
import {Feature} from "./feature"
import {Highlight} from "./highlight"
import {PlayButton} from "./play-button"
import {WaitlistForm} from "./waitlist-form"

const isBrowser = typeof window != "undefined"

export const Landing: FC<{}> = (props) => {
  const heroSize = useBreakpointValue(["2xl", "4xl"])
  const isMobile = useBreakpointValue([true, false])

  const toast = useToast()

  useEffect(() => {
    if (window.location.search.includes("verified=true")) {
      toast({
        position: "top",
        title: "Email verified!",
        description: "You're all set. Talk soon!",
        status: "success",
        duration: 150000,
        isClosable: true,
      })
    }
  }, [])

  return (
    <Box h="full" overflow="auto" textAlign="center" color="black">
      <Box
        bg={`#fff url(/images/pattern@2x.png)`}
        backgroundSize="862px 762px"
        backgroundRepeat="repeat"
        backgroundPosition="center center"
        backgroundAttachment="scroll"
        pt={6}
      >
        <Box px={[6, 10]}>
          <HStack mb={[16, 20]} className="justify-end space-x-10">
            <Link href="/signup">Sign up</Link>
            <Link href="/login">Log in</Link>
          </HStack>

          <VStack spacing={20}>
            <VStack spacing={[8, 12]}>
              <Box scale={[0.8, 1]} transform="auto">
                <Image src="/images/bolt@2x.png" width={58} height={86} />
              </Box>

              <Heading
                size={heroSize}
                fontWeight={700}
                letterSpacing={["-1px", "-2px"]}
              >
                Organize your life
              </Heading>

              <Heading
                size="lg"
                fontWeight={400}
                maxW={[null, 550]}
                letterSpacing="-0.02em"
              >
                Become less stressed by neatly organizing everything you have to
                do.
              </Heading>
            </VStack>

            <WaitlistForm />

            <Box
              shadow={["xl", "none"]}
              borderRadius={["md", "none"]}
              position="relative"
            >
              <PlayButton />

              {isMobile ? (
                <Image
                  src="/images/hero-mobile.webp"
                  width={386}
                  height={211}
                />
              ) : (
                <Image src="/images/hero.webp" width={988} height={601} />
              )}
            </Box>
          </VStack>

          <VStack spacing={[20, 48]} mt={[16, 32]}>
            <Feature title="Priority Columns">
              Instead of throwing everything in one list, you get a separate
              column for each priority. The “Current” column has{" "}
              <Highlight>really important stuff</Highlight>.
            </Feature>

            <Feature title="Estimate Tasks">
              Each task gets a difficulty rating on 1 to 5 scale. Why? So you
              can <Highlight>recognize the quick wins</Highlight> and get them
              out of the way.
            </Feature>

            <Feature title="Snooze Button">
              If a task is not actionable at the moment, just hit the snooze
              button and <Highlight>it will come back</Highlight> when it's time
              to get back to it.
            </Feature>

            <Feature title="Made for Pros">
              Use ⌘K to jump to lists and tasks. Navigate using Vim bindings.
              Keyboard shortcuts for everything. Have we mentioned it works
              offline?
            </Feature>
          </VStack>
        </Box>

        <Box
          bg="white"
          shadow="xl"
          borderTopColor="stone.100"
          borderTopWidth={1}
          p={4}
          py={10}
          mt={[24, 48]}
          fontSize="sm"
          color="stone.500"
        >
          <div>
            Email us at{" "}
            <Text as="a" href="mailto:hello@focustask.app" color="stone.800">
              hello@focustask.app
            </Text>{" "}
            or follow{" "}
            <Text as="a" href="https://twitter.com/focustask" color="stone.800">
              @focustask
            </Text>
          </div>

          <div>
            <a href="/pricing">Pricing</a> |{" "}
            <a href="/privacy-policy">Privacy policy</a> |{" "}
            <a href="/terms-of-use">Terms of use</a>
          </div>
        </Box>
      </Box>
    </Box>
  )
}
