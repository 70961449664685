import {ChakraProvider, extendTheme, ThemeConfig} from "@chakra-ui/react"
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/600.css"
import "@fontsource/rubik/700.css"
import "@fontsource/rubik/800.css"
import {FC} from "react"

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
}

const theme = extendTheme({
  config,
  styles: {
    global: {
      html: {
        // fontSize: "14px",
      },
    },
  },
  textStyles: {
    heading: {},
    rubik: {
      fontFamily: "Rubik, sans-serif",
    },
  },
  fonts: {
    heading: "Rubik, sans-serif",
    // body: "Rubik, sans-serif",
  },
  colors: {
    landingFg: {
      "500": "#FF3270",
      // "600": "#e34669",
    },
    stone: {
      "50": "rgb(250 250 249)",
      "100": "rgb(245 245 244)",
      "200": "rgb(231 229 228)",
      "300": "rgb(214 211 209)",
      "400": "rgb(168 162 158)",
      "500": "rgb(120 113 108)",
      "600": "rgb(87 83 78)",
      "700": "rgb(68 64 60)",
      "800": "rgb(41 37 36)",
      "900": "rgb(28 25 23)",
    },
  },
  fontSizes: {
    "3xl": "1.75rem",
  },
  // fontSizes: {
  //   "2xl": "18px",
  //   xl: "17px",
  //   lg: "16px",
  //   md: "14px",
  //   sm: "13px",
  //   xs: "12px",
  // },
})

/**
 * @deprecated use Tailwind instead
 */
export const LandingTheme: FC = ({children}) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>
}
