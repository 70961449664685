import {Button, Text} from "@chakra-ui/react"
import Play from "icons/play.svg"
import {FC} from "react"

export const PlayButton: FC<{}> = (props) => {
  return (
    <Button
      as="a"
      href="https://www.youtube.com/watch?v=Tvyo-3nh21g"
      target="_blank"
      position="absolute"
      bg="blackAlpha.800"
      _hover={{bg: "blackAlpha.900"}}
      _active={{bg: "black"}}
      color="white"
      display="flex"
      alignItems="center"
      shadow="md"
      left="50%"
      top="47%"
      zIndex={10}
      width="132px"
      height="44px"
      ml="-66px"
      mt="-22px"
    >
      <Play />
      <Text ml={2}>Play video</Text>
    </Button>
  )
}
