import {Landing} from "components/landing/landing"
import {GetServerSideProps} from "next"
import Head from "next/head"
import Script from "next/script"
import nookies from "nookies"
import {LandingTheme} from "themes/landing"

const isBrowser = typeof window != "undefined"

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const cookies = nookies.get(ctx)

  if (cookies["app"] === "1") {
    return {
      redirect: {
        permanent: false,
        destination: "/tasks",
      },
      props: {},
    }
  }

  return {
    props: {},
  }
}

export default function Home() {
  return (
    <LandingTheme>
      <Head>
        <title>FocusTask</title>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, viewport-fit=cover"
        />
        <Script
          src="https://plausible.io/js/plausible.js"
          defer
          data-domain="focustask.app"
        />
      </Head>

      <Landing />
    </LandingTheme>
  )
}
