import {Heading, Text, useBreakpointValue, VStack} from "@chakra-ui/react"
import {FC} from "react"

export const Feature: FC<{title: string}> = ({title, children}) => {
  const headingSize = useBreakpointValue(["xl", "2xl"])

  return (
    <VStack spacing={[4, 6]}>
      <Heading size={headingSize} letterSpacing="-1px">
        {title}
      </Heading>

      <Text
        maxW="650px"
        fontSize={["xl", "3xl"]}
        textStyle="rubik"
        lineHeight="140%"
        letterSpacing={["auto", "-0.5px"]}
      >
        {children}
      </Text>
    </VStack>
  )
}
